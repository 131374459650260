import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MsalMemberOfResponse } from './msal.models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private httpClient: HttpClient
  ) {}

  microsoftLogin() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  microsoftLogout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: location.origin,
    });
  }

  isInGroups(groups: string[]): Observable<boolean> {
    return this.getUserGroups().pipe(
      map(response => {
        const groupIds = response.value.map(group => group.id);
        return groups.some(group => groupIds.includes(group));
      }),
      catchError(() => of(false))
    );
  }

  getUserGroups() {
    return from(
      this.msalService.instance.acquireTokenSilent({
        scopes: ['User.Read'],
      })
    ).pipe(
      switchMap(tokenResponse =>
        this.httpClient.get<MsalMemberOfResponse>(
          'https://graph.microsoft.com/v1.0/me/memberOf',
          {
            headers: {
              Authorization: `Bearer ${tokenResponse?.accessToken}`,
            },
          }
        )
      )
    );
  }
}
